<template lang="pug">
div
  base-snackbar(
    :errors.sync="stockGetErrors"
  )
  base-snackbar(
    :errors.sync="orderDetailPostErrors"
  )
  base-snackbar(
    :errors.sync="orderDetailStatusGetErrors"
  )
  v-data-table(
    :items="dataList"
    :headers="headers"
    hide-default-footer
    dense
  )
    template(v-slot:body.append)
      tr
        th(colspan="6" align="center") Total
        td(align="right") {{ totalAmountItems.phpFormat() }}
    template(v-slot:item="{ item, index }")
      tr
        td {{ item.stockId }}
        td(align="right") {{ item.quantity }}
        td(align="right") {{ item.unitPrice }}
        td(align="right") {{ item.discount }}
        td {{ item.orderDetailStatusId }}
        td {{ item.dateAllocated.dateFormat({}  ) }}
        td(align="right") {{ (item.quantity * (item.unitPrice - item.discount)).phpFormat() }}
        //- td {{ item.purchaseOrderId }}
        //- td {{ item.inventoryId }}
        td(align="center")
          v-btn(
            text
            color="yellow"
            x-small
            @click="editData(item, index)"
          )
            v-icon mdi-pencil
          v-btn(
            text
            color="error"
            x-small
            @click="removeData(index)"
          )
            v-icon mdi-delete
    template(v-slot:footer)
      tr
        td(:colspan="headers.length")
          v-toolbar(
            color="primary"
            dense
            dark
            elevation="0"
          )
            span New Item
          v-container
            v-row
              v-col(lg="2" cols="12")
                v-text-field(
                  label="Date Allocated"
                  v-model="dateAllocated"
                  type="date"
                  :error-messages="orderDetailPostErrors.date_allocated || []"
                )
              v-col(lg="2" cols="12")
                v-autocomplete(
                  label="Stock"
                  :items="computedStockId"
                  item-value="id"
                  item-text="id"
                  :filter="filterStockAutocomplete"
                  v-model="stockId"
                  :error-messages="orderDetailPostErrors.stock_id || []"
                )
                  template(v-slot:item="{ item }")
                    span {{ item.id }}
                    span.ml-2.grey--text.text-uppercase {{ item.description }}
              v-col(lg="2" lg-offset="2" cols="12")
                v-text-field(
                  label="Quantity"
                  type="number"
                  v-model="quantity"
                  :error-messages="orderDetailPostErrors.quantity || []"
                )
              v-col(lg="2" cols="12")
                v-autocomplete(
                  label="Unit"
                  :items="units"
                  v-model="unit"
                )
              v-col(lg="2" cols="12")
                v-text-field(
                  label="Unit Price"
                  type="number"
                  v-model="unitPrice"
                  :error-messages="orderDetailPostErrors.unit_price || []"
                )
              v-col(lg="2" lg-offset="6" cols="12")
                v-text-field(
                  label="Discount"
                  v-model="discount"
                  :error-messages="orderDetailPostErrors.discount || []"
                )
              v-col(lg="4" cols="12")
                v-autocomplete(
                  label="Order Status"
                  :loading="orderDetailStatusGetting"
                  :items="orderDetailStatusList"
                  item-value="id"
                  item-text="name"
                  v-model="orderDetailStatusId"
                  :error-messages="orderDetailPostErrors.order_detail_status_id || []"
                )
              //- v-col(cols="4")
              //-   v-text-field(
              //-     label="Purchase Order"
              //-     v-model="purchaseOrderId"
              //-     :error-messages="orderDetailPostErrors.purchase_order_id || []"
              //-   )
              //- v-col(cols="4")
              //-   v-text-field(
              //-     label="Inventory"
              //-     v-model="inventoryId"
              //-     :error-messages="orderDetailPostErrors.inventory_id || []"
              //-   )
              v-col.mb-2(
                lg="2"
                cols="12"
              )
                v-btn(
                  block
                  tile
                  color="success"
                  :loading="orderDetailPosting"
                  @click="appendData"
                )
                  span ADD
              //- v-col(cols="6")
              //-   v-card
              //-     v-toolbar(
              //-       color="primary"
              //-       dense
              //-       dark
              //-       elevation="0"
              //-     )
              //-       span Invoice
              //-     v-container
              //-       create-invoice(
              //-         :input-fields.sync="invoices"
              //-         :total-amount="totalAmountItems"
              //-       )
  v-dialog(
    v-model="editItemDialog"
    max-width="50vw"
    eager
  )
    v-card
      edit-detail(
        :stocks="stockList"
        @update-detail="updateDetail"
        ref="edit-detail"
        @close="openEditDialog(false)"
      )
</template>
<script>
import VueGetApi from '@/libs/classes/VueGetApi.class'
import stockRepository from '@/repositories/stock.repository'
import orderDetailStatusRepository from '@/repositories/order-detail-status.repository'
import VuePostApi from '@/libs/classes/VuePostApi.class'
import { getVars, postVars } from '@/libs/api-helper.extra'
import orderDetailRepository from '@/repositories/order-detail.repository'

const currentDate = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date
    .getDate()
    .toString()
    .padStart(2, '0')
  return [year, month, day].join('-')
}

const inputVars = attributes => ({
  stockId: null,
  quantity: null,
  unit: null,
  unitPrice: null,
  discount: null,
  orderDetailStatusId: 'NW',
  dateAllocated: currentDate(),
  purchaseOrderId: null,
  inventoryId: null,
  ...attributes,
})

const tableVars = () => ({
  headers: [
    { text: 'Stock', value: 'stockId' },
    { text: 'Quantity', value: 'quantity', sortable: false },
    { text: 'Price/Unit', value: 'unitPrice', sortable: false },
    { text: 'Discount', value: 'discount', sortable: false },
    { text: 'Order Status', value: 'orderStatus', sortable: false },
    { text: 'Date Allocated', value: 'dateAllocated', sortable: false },
    { text: 'Amount', sortable: false },
    // { text: 'Purchase Order', value: 'purchaseOrder', sortable: false },
    // { text: 'Inventory', value: 'inventory', sortable: false },
    { text: 'Config', sortable: false, width: '150px' },
  ],
  dataList: [],
})

export default {
  name: 'CreateOrderDetail',
  props: {
    orderId: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    revenueSourceId: {
      type: String,
      default: null,
    },
  },
  components: {
    editDetail: () => import('./Edit'),
    // createInvoice: () => import('./../invoices/Create'),
  },
  created() {
    this.getStocks()
    this.getOrderDetailStatus()
  },
  data: () => ({
    ...inputVars(),
    ...tableVars(),
    ...getVars('stock'),
    ...postVars('order-detail'),
    ...getVars('order-detail-status'),
    editItemDialog: false,
    // invoices: {},
  }),
  computed: {
    computedStockId() {
      const notExistsFilter = stock =>
        this.dataList.findIndex(item => stock.id === item.stockId) === -1
      const revenueSourceFilter = stock =>
        !this.revenueSource || this.revenueSource.id === stock.revenue_source.id
      const filterData = stock => notExistsFilter(stock) && revenueSourceFilter(stock)
      return this.stockList.filter(filterData)
    },
    revenueSource() {
      if (!this.dataList.length) return null
      const findStock = item => item.id === this.dataList[0].stockId
      const stock = this.stockList.find(findStock)
      
      return Window.objectChangeKeyCase(stock.revenue_source)
    },
    totalAmountItems() {
      const sum = (a, b) => a + b.quantity * (b.unitPrice - b.discount)
      return this.dataList.reduce(sum, 0)
    },
    selectedStock() {
      const findStock = item => item.id === this.stockId
      return this.computedStockId.find(findStock)
    },
    units() {
      const stock = this.selectedStock || {}
      if (stock.stock_category_id === 'EG01') {
        return ['egg']
      }
      return ['kg', 'bag', 'lot', 'loan']
    },
  },
  watch: {
    units(units) {
      const stock = this.selectedStock || {}
      if (stock.stock_category_id === 'EG01') {
        this.unit = 'egg'
        return
      }
      this.unit = 'bag'
    },
    dataList(val) {
      this.updateItemsProp(val)
    },
    stockId() {
      this.fillInUnitPrice()
    },
    unit() {
      this.fillInUnitPrice()
    },
    revenueSource({ id }) {
      this.$emit('update:revenueSourceId', id)
    },
  },
  methods: {
    updateItemsProp(val) {
      this.$emit('update:items', val)
    },
    fillInUnitPrice() {
      const stock = this.selectedStock
      if (!this.stockId || !this.unit) return
      if (stock.stock_category_id === 'EG01') {
        this.balutUnitPrice(stock)
        return
      }
      this.defaultUnitPrice(stock)
    },
    balutUnitPrice(stock) {
      this.unitPrice = stock.default_detail.price
    },
    defaultUnitPrice(stock) {
      const { unit } = this
      if (unit === 'bag') {
        this.unitPrice = stock.feed_detail.resell_per_bag || null
        return
      }
      this.unitPrice = stock.feed_detail.resell_per_kilo || null
    },
    appendData() {
      if (this.orderDetailPosting) return
      const requestVarNames = this.postRequestOrderDetailVarNames()
      const postRequestApi = new VuePostApi(this, requestVarNames)
      const inputData = this.getInputData()
      const data = this.$objectChangeKeyCase(inputData, 'camelToSnakeCase')
      const repository = orderDetailRepository.validate(data)
      postRequestApi.send(repository, () => {
        this.dataList.push(inputData)
        
        this.reset()
      })
    },
    getOrderDetailStatus() {
      if (this.orderDetailStatusGetting) return
      const getRequestVarNames = this.getOrderDetailStatusGetRequestVarNames()
      const getRequestApi = new VueGetApi(this, getRequestVarNames)
      const repository = orderDetailStatusRepository.index()
      getRequestApi.fetch(repository)
    },
    getOrderDetailStatusGetRequestVarNames() {
      const BASE_NAME = 'orderDetailStatus'
      return {
        loading: `${BASE_NAME}Getting`,
        error: `${BASE_NAME}GetErrors`,
        list: `${BASE_NAME}List`,
      }
    },
    postRequestOrderDetailVarNames() {
      const BASE_NAME = 'orderDetail'
      return {
        loading: `${BASE_NAME}Posting`,
        error: `${BASE_NAME}PostErrors`,
      }
    },
    getStocks() {
      if (this.stockGetting) return
      const requestVarNames = this.getStockGetRequestVarNames()
      const getRequestApi = new VueGetApi(this, requestVarNames)
      const repository = stockRepository.hasFeedDetail()
      getRequestApi.fetch(repository)
    },
    removeData(index) {
      this.dataList.splice(index, 1)
    },
    getStockGetRequestVarNames() {
      const BASE_NAME = 'stock'
      return {
        loading: `${BASE_NAME}Getting`,
        error: `${BASE_NAME}GetErrors`,
        list: `${BASE_NAME}List`,
      }
    },
    getInputData() {
      const data = inputVars({ orderDetailStatusId: null })
      Object.keys(data).forEach(key => {
        data[key] = this[key]
      })
      return data
    },
    openEditDialog(open = true) {
      this.editItemDialog = open
    },
    updateDetail(data, index) {
      // 
      this.dataList[index] = data
      this.dataList = this.dataList.map(item => ({ ...item }))
      this.openEditDialog(false)
    },
    reset() {
      const defaults = inputVars()
      Object.keys(defaults).forEach(key => {
        this[key] = defaults[key]
      })
    },
    editData(item, index) {
      const { populateInputs, setCurrentIndex } = this.$refs['edit-detail']
      populateInputs(item)
      setCurrentIndex(index)
      this.openEditDialog()
    },
    filterStockAutocomplete(item, queryText) {
      const regExp = new RegExp(queryText, 'i')
      return regExp.test(item.id) || regExp.test(item.description)
    },
  },
}
</script>
